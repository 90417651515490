import { useState, useEffect, FunctionComponent } from 'react';
import { Grid, TableRow, TableCell, Button } from '@mui/material';
import { Select, SelectMultiple, Table, Typography } from './../../MaterialUiComponents';
import { CertificateData, Certificates } from './../../types/certificates';
import { CheckCircle, RemoveCircle, Info } from '@mui/icons-material';
import Certificate from './Certificate';
import { makeStyles } from '@mui/styles';
import { EmployeeSearch, ProfileImg } from '../../components';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import CertificatesApi from './../../api/certificates';
import { TableColumns } from '../../types/table';

const useStyles = makeStyles(theme => ({
    userPhoto: {
        height: theme.spacing(5),
        width: theme.spacing(5),
        borderRadius: "50%"
    },
    employeeSearch: {
        marginLeft: '2%'
    },
    valid: {
        color: 'green'
    },
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
}));

type CertificateProps = {
    setLoading: Function,
    userType: string
}

type FlattenedCertificateObjects = {
    mechanic_full_name: string | undefined;	
    rating_name: string | undefined;
    aircraft_category_name: string | undefined;
    expiration_date_text: string | undefined;
    certificate_id: number | undefined;
}

const sortTableColumns = [
    { label: "Employee Name", key: "mechanic_full_name" },
    { label: "Issue Date", key: "quality_assurance_accepted_date" },
    { label: "Expiration Date", key: "certificate_expired_date" },
    { label: "Status", key: "expired_date_status" },
] as TableColumns[];

const AllCertificates: FunctionComponent<CertificateProps> = ({ setLoading, userType }) => {
    const [allCertificates, setAllCertificates] = useState<Array<Certificates>>([]);
    const [selectedCertificate, setSelectedCertificate] = useState<CertificateData>();
    const [selectedType, setSelectedType] = useState<string>("All Certificates");
    const [editRights, setEditRights] = useState(true);
    const store = useSelector((state: RootState) => state);
    const allUsers = store.employees.all_employees;
    const [orderByText, setOrderByText] = useState("Issue Date");
    const [ascending, setAscending] = useState(false);
    const [filteredCertificates, setFilteredCertificates] = useState<Array<Certificates>>([]);
    const [selectedFilteredRatings, setSelectedFilteredRatings] = useState<string[]>([]);
    const [ratingOptions, setRatingOptions] = useState<string[]>([]);
    const [selectedFilteredAirCrafts, setSelectedFilteredAircrafts] = useState<string[]>([]);
    const [aircraftsOptions, setAircraftsOptions] = useState<string[]>([]);
    const classes = useStyles();
    const isCertificateEditor = store.user.me.user_clearence_groups.includes("Certificates Create");

    const fetchFilteredCertificates = async () => {
        const certificates = await CertificatesApi.GetAllFilteredCertificatesByIds(filteredCertificates.map(cert => cert.certificate_id));
        
        const preppedCertificates = flattenArray(certificates.data);
        downloadCSV(preppedCertificates, "Certificates download");
    }

    const flattenArray = (certificateArray: CertificateData[]): FlattenedCertificateObjects[] => {
        return certificateArray.flatMap(({ mechanic_full_name, certificate_rights, certificate_id}) =>
            certificate_rights.map(({ rating_name, aircraft_category_name, expiration_date_text }) => ({
                mechanic_full_name,
                rating_name,
                aircraft_category_name,
                expiration_date_text,
                certificate_id: certificate_id
            }))
        );
    }

    const filterCert = (type: string, ratings: string[], allCert: Certificates[], aircrafts: string[]) => { 
        let filterCertificate = allCert;
        
        if(type === "Icelandair Employees") {
            filterCertificate = allCert.filter(cert => cert.company_id  === 1);
        }
        else if(type === "Subcontracts") {
            filterCertificate = allCert.filter(cert => cert.company_id  !== 1);
        }
 
        if(ratings.length > 0) {
            filterCertificate = filterCertRatingsObjects(ratings, filterCertificate);;
        }

        if(aircrafts.length > 0) {
            filterCertificate = filterCertAircraftObjects(aircrafts, filterCertificate);
        }

        setFilteredCertificates(filterCertificate);
    }

    useEffect(() => {
        setEditRights(isCertificateEditor);
        refresh(orderByText, ascending);
    }, []);

    const refresh = async (orderByText: string, ascending: Boolean) => {
        setLoading(true);
        let tempRating: Array<string> = [];
        let tempAircrafts: Array<string> = [];
        
        try {
            const ratingRes = await CertificatesApi.GetAllCertificationRatings();
            tempRating = ratingRes.data.map(rating => rating.rating);
            setRatingOptions(tempRating);
    
            const certRes = await CertificatesApi.GetAllValidCertificates(orderByText, ascending, "All Certificates");
            setAllCertificates(certRes.data);
            setFilteredCertificates(certRes.data);


            if(certRes.data.map(x => x.aircrafts).length === 0) {
                console.log("Aircraft")
            }
            console.log("cert", certRes)

            const aircraftsRes = await CertificatesApi.GetAllCertififcateAircraftCategories();
            tempAircrafts = aircraftsRes.data.map(aircraft => aircraft.aircraft_category);
            setAircraftsOptions(tempAircrafts);

            filterCert(selectedType, selectedFilteredRatings, certRes.data, selectedFilteredAirCrafts);
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    }

    const createNewCertificate = () => {
        setSelectedCertificate({ certificate_rights: [] });
    }
    const onTypeChange = (type: any) => {
        setSelectedType(type);
        filterCert(type, selectedFilteredRatings, allCertificates, selectedFilteredAirCrafts);
    }

    function filterCertRatingsObjects(filters: string[], certificates: Certificates[]): Certificates[] {
        return certificates.filter(obj => obj.ratings.some(rating => filters.includes(rating)));
    }

    function filterCertAircraftObjects(filters: string[], certificates: Certificates[]): Certificates[] {
        return certificates.filter(obj => obj.aircrafts.some(x => filters.includes(x)));
    }
    
    const onRatingChange = (ratings: any) => {
        setSelectedFilteredRatings(ratings);
        filterCert(selectedType, ratings, allCertificates, selectedFilteredAirCrafts);
    }

    const onAircraftChange = (aircrafts: any) => {
        setSelectedFilteredAircrafts(aircrafts);
        filterCert(selectedType, selectedFilteredRatings, allCertificates, aircrafts);
    }

    const generateTableBody = (row: Certificates, index: number) => {
        return (
            <TableRow className={index % 2 ? classes.evenRows : ''} onClick={() => onRowClick(row)} >
                <TableCell>{index}</TableCell>
                <TableCell >
                    <Grid container>
                        <ProfileImg imgKey={index} userName={allUsers.find(s => s.Id === row.mechanic_employee_id)?.userName?.split('@')[0]} />
                        <Typography margin={[1, 0, 0, 2]} align="left" text={row.mechanic_full_name}></Typography>
                    </Grid>
                </TableCell>
                <TableCell>{row.quality_assurance_accepted_date_text}</TableCell>
                <TableCell>{row.certificate_expired_date_text} </TableCell>
                <TableCell>
                    {row.expired_date_status.toString() === 'Valid' ? <CheckCircle className={classes.valid} />
                        :
                        row.expired_date_status.toString() === 'Expiring' ? <Info color="secondary" />
                            : row.certificate_expired_date_text !== "" ?
                            <RemoveCircle color="error" /> : ""
                    }
                </TableCell>
            </TableRow>
        )
    }
    const onRowClick = (row: Certificates) => {
        CertificatesApi.GetCertificateById(row.certificate_id).then(res => {
            setSelectedCertificate(res.data);
        })
    }
    const closeCertificate = () => {
        setSelectedCertificate(undefined);
        refresh(orderByText, ascending);
    }
    const OnOrderByTitles = (givenOrderByText: string) => {
        if (orderByText === givenOrderByText) {
            setAscending(!ascending);
        }
        else {
            setAscending(true);
        }
        setOrderByText(givenOrderByText);
    }
    const onSelectedEmployeeRow = (employeeId: number) => {
        let tempCertificate;

        setSelectedFilteredRatings([]);
        setSelectedType("All Certificates");

        if (employeeId === null || employeeId === 0) {
            tempCertificate = allCertificates;
            setFilteredCertificates(tempCertificate);
        }
        else {
            const selectedEmployee = allUsers.filter(s => s.user_id === employeeId);

            if(selectedEmployee.length === 0){

                // if the employee is not found in the list of all employees, then the employee is deactivated and we need to get his certificate from the database
                CertificatesApi.GetMechanicsValidCertificatesByEmployeeId(employeeId).then(res => {
                    tempCertificate = res.data;
                    setFilteredCertificates(tempCertificate);
                });
            }
            else{
                tempCertificate = allCertificates.filter(cert => cert.mechanic_full_name === selectedEmployee[0].name);
                setFilteredCertificates(tempCertificate);
            }
        }
        
    }
    return (
        <Grid>
            {selectedCertificate === undefined ? <Grid>
                <Grid container spacing={8}>
                    <Grid item className={classes.employeeSearch} xs={4}> <EmployeeSearch place_holder={"Write employees name"} showDisabledEmployees onSelectedEemployee={(employeeId: number) => onSelectedEmployeeRow(employeeId)} /> </Grid>
                    <Grid item xs={2}>
                            <Select onSelectedItem={(e: any) => onTypeChange(e.target.value)} selectedItem={selectedType} width={200} filter_by_text={"Certificate Type"} options={["All Certificates", "Icelandair Employees", "Subcontracts"]} />
                    </Grid>
                    <Grid item xs={2}>
                        <SelectMultiple onSelectedItem={(e: any) => onRatingChange(e.target.value)} selectedItemsString={selectedFilteredRatings} width={200} filter_by_text={"Certificate Ratings"} options={ratingOptions} />
                    </Grid>
                    <Grid item xs={2}>
                        <SelectMultiple onSelectedItem={(e: any) => onAircraftChange(e.target.value)} selectedItemsString={selectedFilteredAirCrafts} width={200} filter_by_text={"Aircrafts"} options={aircraftsOptions} />
                    </Grid>
                    {editRights === true &&
                        <Grid item xs={1.5}><Button variant="contained" onClick={() => createNewCertificate()} color="primary">New Certificate</Button></Grid>
                    }
                    <Grid item xs={1.5}>
                        <Button variant="contained" color="primary" onClick={() => fetchFilteredCertificates()} >Export to csv</Button>
                    </Grid>
                </Grid>
                <Grid>
                    <Table
                        sortTableColumns={sortTableColumns}
                        headers={["Employee Name", "Issue Date", "Expiration Date", "Status"]}
                        rows={filteredCertificates}
                        generateBody={(row: any, index: number) => generateTableBody(row, index)}
                        defaultRowsPerPage={25}
                        OnOrderByTitles={(orderByText: string) => OnOrderByTitles(orderByText)}
                    />
                </Grid>
            </Grid>
                :
                <Grid>
                    <Certificate
                        setLoading={(loadingStatus: boolean) => setLoading(loadingStatus)}
                        selectedCertificate={selectedCertificate}
                        closeCertificate={() => closeCertificate()}
                        viewType={editRights ? 'edit' : 'view'}
                        userType={userType}
                    />
                </Grid>
            }
        </Grid>
    );
};
export default AllCertificates;

const convertToCSV = (data: FlattenedCertificateObjects[]): string => {
    const csvRows: string[] = [];

    const headers = ['name', 'rating', 'aircraft', 'exp_date', 'certificate id'];
    csvRows.push(headers.join(';')); // Change delimiter to semicolon

    for (const row of data) {
        const values = [
            `"${row.mechanic_full_name}"`, 
            `"${row.rating_name}"`, 
            `"${row.aircraft_category_name}"`, 
            `"${row.expiration_date_text}"`,
            `"${row.certificate_id}"`,
        ];
        csvRows.push(values.join(';')); // Change delimiter to semicolon
    }

    return csvRows.join('\n');
}

const downloadCSV = (data: FlattenedCertificateObjects[], filename: string) => {
    const csv = convertToCSV(data);

    const bom = "\uFEFF";
    const blob = new Blob([bom + csv], { type: 'text/csv;charset=utf-8;' });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
